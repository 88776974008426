import React from 'react'
import { Link } from 'react-router-dom'
import { properties } from '../../utils/Properties_es';
/*****************************************************************************
 * REVISION 1.0
 * Fecha: 05/01/2023
 *
 * @description Componente para navegacion de dahsboard
 * @author luis.ferrer, deniree.rodriguez
 * @version 1.0
 *
 ****************************************************************************/
const Navigation = ({ name, parentLink, parentName }) => {
  return (
    <div className="col-12 dorder-btm pb-1">
      <div className="row">
        <div className="col-12">
          <nav aria-label="breadcrumb m-0">
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item">
                <Link to="/board/home">
                  {properties.com_parval_label_dashboard_menu_inicio}
                </Link>
              </li>
              {parentLink && (
                <li className="breadcrumb-item">
                  <Link to={parentLink}>{parentName}</Link>
                </li>
              )}

              <li className="breadcrumb-item active" aria-current="page">
                {name}
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Navigation;