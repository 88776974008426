import { CONSTANT } from "./Constant";
export const URL = {
  /** HOME PATH */
  LOGIN_TWOFACTORCODE_REQUEST: CONSTANT.DISPATCHER_USER_PREFIX + "/user/login/twofactor",
  LOGIN_REQUEST: CONSTANT.DISPATCHER_USER_PREFIX + "/user/autenticate",
  LOGIN_RECOVERY_PASS_REQUEST: CONSTANT.DISPATCHER_USER_PREFIX + "/user/recoverPasswordAdmin",
  LOGIN_CHANGE_PASS_REQUEST: CONSTANT.DISPATCHER_USER_PREFIX + "/user/changePassword",
  LOGIN_ACTIVE_USER_REQUEST: CONSTANT.DISPATCHER_USER_PREFIX + "/user/activate",

  /** maintenance urls */
  REQUEST_CERTCATEGORY_LIST: (department, page, size) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/certificate-categories/${department}/${page}/${size}`,
  REQUEST_CERTCATEGORY: (id) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/certificate-categories/${id ? id : ""}`,

  REQUEST_CERTIFICATE_LIST: (department, page, size) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/certificates/${department}/${page}/${size}`,
  REQUEST_CERTIFICATE: (id) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/certificates/${id ? id : ""}`,

  REQUEST_MANAGEMENT_LIST: (page, size) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/managements/${page}/${size}`,
  REQUEST_MANAGEMENT_DETAILS_LIST: (page, size) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/managements/details/${page}/${size}`,
  REQUEST_MANAGEMENT_TOTALS: (page, size) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + '/managements/totals',
  REQUEST_MANAGEMENT: (id) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/managements/${id ? id : ""}`,

  REQUEST_GEN_DOCUMENT: (data) =>
    `${CONSTANT.DISPATCHER_MAINTENANCE_PREFIX}/document/pdf/contract/${data.bank}/${data.primerCotitular_name}/${data.primerCotitular_rif}/${data.fecha_contrato}/${data.cuenta}/${data.number_account_parval}/${data.ctacevaldom}/${data.name_account_parval}/`,
  REQUEST_GEN_DOCUMENT_INDIVIDUAL: (data) =>
    `${CONSTANT.DISPATCHER_MAINTENANCE_PREFIX}/document/pdf/individual/${data.bank}/${data.primerCotitular_name}/${data.segundoCotitular_name}/${data.primerCotitular_rif}/${data.segundoCotitular_rif}/${data.fecha_contrato}/${data.cuenta}/${data.number_account_parval}/${data.ctacevaldom}/${data.name_account_parval}/`,
  REQUEST_GEN_DOCUMENT_MANCOMUNADA: (data) =>
    `${CONSTANT.DISPATCHER_MAINTENANCE_PREFIX}/document/pdf/mancomunada/${data.bank}/${data.primerCotitular_name}/${data.segundoCotitular_name}/${data.primerCotitular_rif}/${data.segundoCotitular_rif}/${data.fecha_contrato}/${data.cuenta}/${data.number_account_parval}/${data.ctacevaldom}/${data.name_account_parval}/`,
  /** users urls */
  REQUEST_USER_LIST: (page, size) => CONSTANT.DISPATCHER_USER_PREFIX + `/user/${page}/${size}`,
  REQUEST_USER_CREATE_ADMIN: CONSTANT.DISPATCHER_USER_PREFIX + `/user/createAdmin`,
  REQUEST_USER: (id) => CONSTANT.DISPATCHER_USER_PREFIX + `/user/${id ? id : ""}`,

  /** role urls */
  REQUEST_ROLE_LISTALL: CONSTANT.DISPATCHER_USER_PREFIX + `/role/`,
  REQUEST_ROLE_LIST: (page, size) => CONSTANT.DISPATCHER_USER_PREFIX + `/role/${page}/${size}`,
  REQUEST_ROLE_CREATE: CONSTANT.DISPATCHER_USER_PREFIX + `/role/create`,
  REQUEST_ROLE: (id) => CONSTANT.DISPATCHER_USER_PREFIX + `/role/${id ? id : ""}`,

  /** authority */
  REQUEST_AUTHORITY_LIST: (page, size) =>
    CONSTANT.DISPATCHER_USER_PREFIX + `/authorities/${page}/${size}`,
  REQUEST_AUTHORITY_BY_ROLE_LIST: (roleId, page, size) =>
    CONSTANT.DISPATCHER_USER_PREFIX + `/authorities/${roleId}/${page}/${size}`,
  REQUEST_AUTHORITY: (id) => CONSTANT.DISPATCHER_USER_PREFIX + `/authorities/${id ? id : ""}`,

  /** authority for role */
  REQUEST_AUTHORITYROLES_LIST: (roleId, page, size) =>
    CONSTANT.DISPATCHER_USER_PREFIX + `/authorities-roles/${roleId}/${page}/${size}`,
  REQUEST_AUTHORITYROLES: (id) =>
    CONSTANT.DISPATCHER_USER_PREFIX + `/authorities-roles/${id ? id : ""}`,

  /** PARVAL EXTERNAL API */
  CLIENTACCOUNTS_REQUEST: "/user/cuentas",
  CLIENTPROFILE_REQUEST: "/user/perfil",
};
