import ReactDatePicker from "react-datepicker";
import { properties } from "../../../../utils/Properties_es";
import { useFilter } from "../../../../hooks/useFilter";
import { CONSTANT } from "../../../../utils/Constant";

export const ReportsFilters = () => {
  const [, { filter, setFilter, handleClearFilter, handleApplyFilter }] =
    useFilter();

  return (
    <>
      <div className="row">
        <div className="col-12 ">
          {properties.com_parval_label_table_filter_title2}
        </div>
      </div>
      <div className="row pt-3">
        <div className="col-3">
          <div className="form-group">
            <ReactDatePicker
              className="form-control"
              dateFormat="dd/MM/yyyy"
              minDate={filter.minDate}
              maxDate={filter.maxDate}
              selected={filter.startDate}
              onChange={(date) => setFilter({ ...filter, startDate: date })}
              showDisabledMonthNavigation
            />
          </div>
        </div>
        <div className="col-3">
          <div className="form-group">
            <ReactDatePicker
              className="form-control"
              dateFormat="dd/MM/yyyy"
              minDate={filter.minDate}
              maxDate={filter.maxDate}
              selected={filter.endDate}
              onChange={(date) => setFilter({ ...filter, endDate: date })}
              showDisabledMonthNavigation
            />
          </div>
        </div>
        <div className="col-3">
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder={properties.com_parval_label_identification}
              value={filter.identification}
              name="identification"
              maxLength="20"
              onChange={(e) =>
                setFilter({ ...filter, identification: e.target.value })
              }
            />
          </div>
        </div>
        <div className="col-3">
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder={properties.com_parval_label_clientcode}
              value={filter.clientCode}
              name="clientCode"
              maxLength="20"
              onChange={(e) =>
                setFilter({ ...filter, clientCode: e.target.value })
              }
            />
          </div>
        </div>
      </div>
      <div className="row pt-4">
        <div className="col-3">
          <div className="form-group">
            <select
              className={`form-control form-select`}
              id="status"
              name="status"
              value={filter.status}
              onChange={(e) => setFilter({ ...filter, status: e.target.value })}
            >
              <option value={0} key={0}>
                {properties.com_parval_label_selectstatus}
              </option>
              <option
                value={CONSTANT.MANAGEMENT_STATUS_SLC}
                key={CONSTANT.MANAGEMENT_STATUS_SLC}
              >
                {properties.com_parval_label_management_status_slc_title}
              </option>
              <option
                value={CONSTANT.MANAGEMENT_STATUS_ECV}
                key={CONSTANT.MANAGEMENT_STATUS_ECV}
              >
                {properties.com_parval_label_management_status_ecv_title}
              </option>
              <option
                value={CONSTANT.MANAGEMENT_STATUS_PCO}
                key={CONSTANT.MANAGEMENT_STATUS_PCO}
              >
                {properties.com_parval_label_management_status_pco_title}
              </option>
              <option
                value={CONSTANT.MANAGEMENT_STATUS_POP}
                key={CONSTANT.MANAGEMENT_STATUS_POP}
              >
                {properties.com_parval_label_management_status_pop_title}
              </option>
              <option
                value={CONSTANT.MANAGEMENT_STATUS_FOP}
                key={CONSTANT.MANAGEMENT_STATUS_FOP}
              >
                {properties.com_parval_label_management_status_fop_title}
              </option>
              <option
                value={CONSTANT.MANAGEMENT_STATUS_FCO}
                key={CONSTANT.MANAGEMENT_STATUS_FCO}
              >
                {properties.com_parval_label_management_status_fco_title}
              </option>
              <option
                value={CONSTANT.MANAGEMENT_STATUS_PSC}
                key={CONSTANT.MANAGEMENT_STATUS_PSC}
              >
                {properties.com_parval_label_management_status_psc_title}
              </option>
              <option
                value={CONSTANT.MANAGEMENT_STATUS_FSC}
                key={CONSTANT.MANAGEMENT_STATUS_FSC}
              >
                {properties.com_parval_label_management_status_fsc_title}
              </option>
            </select>
          </div>
        </div>
        <div className="col-9 text-end">
          <button
            type="button"
            className="btn btn-secondary me-md-2"
            onClick={handleApplyFilter}
          >
            {properties.com_parval_label_button_search_title}
          </button>
          <button
            type="button"
            className="btn btn-success"
            onClick={handleClearFilter}
          >
            {properties.com_parval_label_button_clear_title}
          </button>
        </div>
      </div>
    </>
  );
};
