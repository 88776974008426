import React from "react";
import Pagination from "react-js-pagination";
/*****************************************************************************
 * REVISION 1.0
 * Fecha: 03/01/2023
 *
 * @description componente para manejo de paginacion
 * @author lferrer
 * @version 1.0
 *
 ****************************************************************************/
const CustomPagination = (props) => {
  const initRow = (props.activePage) * props.itemsCountPerPage;
  const finalRow = initRow + props.itemsCountPerPage;

  return (
    <div className="row">
      <div className="col-sm-12 col-md-5">
        <div
          className="dataTables_info"
          id="dataTable_info"
          role="status"
          aria-live="polite"
        >
          {`Mostrando ${initRow + 1} a ${finalRow} de ${
            props.totalItemsCount
          } registros`}
        </div>
      </div>
      <div className="col-sm-12 col-md-7">
        <Pagination
              activePage={props.activePage + 1}
              itemsCountPerPage={props.itemsCountPerPage}
              totalItemsCount={props.totalItemsCount}
              pageRangeDisplayed={5}
              innerClass={props.innerClass}
              itemClass={props.itemClass}
              linkClass={props.linkClass}
              onChange={props.callback}
          />
      </div>
    </div>
  );
};

export default CustomPagination;
