import { CONSTANT } from "./Constant";
import { properties } from "./Properties_es";

/* eslint-disable */
export const validateFieldStyle = (applyStyle, fieldError) => {
  let style = "";
  if (applyStyle) {
    style = "is-valid";
    if (fieldError) {
      style = "is-invalid";
    }
  }
  return style;
};

export const isValidEmail = (email) => {
  let re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const minToHours = (min) => {
  if (!min || min <= 0) {
    return properties.com_parval_label_certificate_generationtime_desc_format(
      0,
      0
    );
  }
  var hours = min / 60;
  var rhours = Math.floor(hours);
  var minutes = (hours - rhours) * 60;
  var rminutes = Math.round(minutes);
  return properties.com_parval_label_certificate_generationtime_desc_format(
    rhours,
    rminutes
  );
};

/**
 * Función que convierte un número de minutos
 * a su representación legible en horas, días, semanas,
 * meses y años. Por ejemplo:
 *
 * 72080 minuto(s) son 1 mes, 2 semanas, 6 días, 1 hora, 20 minutos
 *
 */
export const minToTime = (minutos) => {
  const leyenda = (numero, palabra, plural) =>
    numero === 0 || numero > 1
      ? `${numero} ${palabra}${plural || "s"}`
      : `${numero} ${palabra}`;
  const MINUTOS_POR_HORA = 60,
    HORAS_POR_DIA = 24,
    DIAS_POR_SEMANA = 7,
    DIAS_POR_MES = 30,
    MESES_POR_ANIO = 12;
  if (minutos < MINUTOS_POR_HORA) return leyenda(minutos, "minuto");
  let horas = Math.floor(minutos / MINUTOS_POR_HORA),
    minutosSobrantes = minutos % MINUTOS_POR_HORA;
  if (horas < HORAS_POR_DIA)
    return (
      leyenda(horas, "hora") +
      (minutosSobrantes > 0 ? ", " + minToTime(minutosSobrantes) : "")
    );
  let dias = Math.floor(horas / HORAS_POR_DIA);
  minutosSobrantes = minutos % (MINUTOS_POR_HORA * HORAS_POR_DIA);
  if (dias < DIAS_POR_SEMANA)
    return (
      leyenda(dias, "día") +
      (minutosSobrantes > 0 ? ", " + minToTime(minutosSobrantes) : "")
    );
  let semanas = Math.floor(horas / (HORAS_POR_DIA * DIAS_POR_SEMANA));
  minutosSobrantes =
    minutos % (MINUTOS_POR_HORA * HORAS_POR_DIA * DIAS_POR_SEMANA);
  if (dias < DIAS_POR_MES)
    return (
      leyenda(semanas, "semana") +
      (minutosSobrantes > 0 ? ", " + minToTime(minutosSobrantes) : "")
    );
  let meses = Math.floor(horas / (HORAS_POR_DIA * DIAS_POR_MES));
  minutosSobrantes =
    minutos % (MINUTOS_POR_HORA * HORAS_POR_DIA * DIAS_POR_MES);
  if (meses < MESES_POR_ANIO)
    return (
      leyenda(meses, "mes", "es") +
      (minutosSobrantes > 0 ? ", " + minToTime(minutosSobrantes) : "")
    );
  let anios = Math.floor(
    horas / (HORAS_POR_DIA * DIAS_POR_MES * MESES_POR_ANIO)
  );
  minutosSobrantes =
    minutos %
    (MINUTOS_POR_HORA * HORAS_POR_DIA * DIAS_POR_MES * MESES_POR_ANIO);
  return (
    leyenda(anios, "año") +
    (minutosSobrantes > 0 ? ", " + minToTime(minutosSobrantes) : "")
  );
};

export const convertTo2Digits = (num) => {
  return num.toString().padStart(2, "0");
};

export const formatDate = (date) => {
  return [
    convertTo2Digits(date.getDate()),
    convertTo2Digits(date.getMonth() + 1),
    date.getFullYear(),
  ].join("/");
};

/**
 *
 * Convertir codigo de departamento a titulo
 *
 * @param status
 * @returns departamento title
 * @author lferrer
 */
export const validateDepartmentCode = (departmentCode) => {
  let label = "";
  switch (departmentCode) {
    case CONSTANT.DEPARTMENT_CON_VALUE:
      label = properties.com_parval_label_department_con_title;
      break;
    case CONSTANT.DEPARTMENT_OPE_VALUE:
      label = properties.com_parval_label_department_ope_title;
      break;
    case CONSTANT.DEPARTMENT_SEC_VALUE:
      label = properties.com_parval_label_department_sec_title;
      break;
    case CONSTANT.DEPARTMENT_CUM_VALUE:
      label = properties.com_parval_label_department_cum_title;
      break;
    case CONSTANT.DEPARTMENT_ALL_VALUE:
      label = properties.com_parval_label_department_all_title;
      break;
    default:
      break;
  }

  return label;
};

export const parseDate = (date) => {
  if (date) {
    let parsedDate = date.replaceAll("/", "-");
    parsedDate = parsedDate.split("-").reverse().join("-");
    return parsedDate;
  }
  return "";
};

export const parseDateRD = (date) => {
  if (date) {
    let parsedDate = date.replaceAll("/", "-");
    parsedDate = parsedDate.split("-").reverse().join("-");
    return parsedDate + "T00:00:00";
  }
  return "";
};

export const validarFecha = (fecha) => {
  if (fecha === null || fecha === "") {
    return null;
  }

  const dateObj = new Date(fecha);

  if (isNaN(dateObj.getTime())) {
    return null;
  }

  return dateObj;
};

export const capitalizeFullName = (fullName) => {
  if (fullName) {
    let capitalizedFullName = fullName
      .toLowerCase()
      .split(" ")
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(" ");
    return capitalizedFullName;
  }
  return "";
};

export const validateStatus = (status) => {
  let label = "";
  switch (status) {
    case CONSTANT.MANAGEMENT_STATUS_SLC:
      label = properties.com_parval_label_management_status_slc_title;
      break;
    case CONSTANT.MANAGEMENT_STATUS_ECV:
      label = properties.com_parval_label_management_status_ecv_title;
      break;
    case CONSTANT.MANAGEMENT_STATUS_PCO:
      label = properties.com_parval_label_management_status_pco_title;
      break;
    case CONSTANT.MANAGEMENT_STATUS_POP:
      label = properties.com_parval_label_management_status_pop_title;
      break;
    case CONSTANT.MANAGEMENT_STATUS_FOP:
      label = properties.com_parval_label_management_status_fop_title;
      break;
    case CONSTANT.MANAGEMENT_STATUS_FCO:
      label = properties.com_parval_label_management_status_fco_title;
      break;
    case CONSTANT.MANAGEMENT_STATUS_PSC:
      label = properties.com_parval_label_management_status_psc_title;
      break;
    case CONSTANT.MANAGEMENT_STATUS_FSC:
      label = properties.com_parval_label_management_status_fsc_title;
      break;
    default:
      label = properties.com_parval_label_management_status_unknown_title;
      break;
  }

  return label;
};

export const validateInvoiceType = (invoiceType) => {
  let label = "";
  switch (invoiceType) {
    case CONSTANT.INVOICE_TYPE_1:
      label = properties.com_parval_label_management_invoicetype1_title;
      break;
    case CONSTANT.INVOICE_TYPE_2:
      label = properties.com_parval_label_management_invoicetype2_title;
      break;
    case CONSTANT.INVOICE_TYPE_3:
      label = properties.com_parval_label_management_invoicetype3_title;
      break;

    case CONSTANT.INVOICE_TYPE_4:
      label = properties.com_parval_label_management_invoicetype4_title;
      break;

    default:
      label = properties.com_parval_label_management_status_unknown_title;
      break;
  }

  return label;
};
