import {
  formatDate,
  validateInvoiceType,
  validateStatus,
} from "../../../../utils/UtilsFunction";

export const ReportsTR = ({ row }) => {
  return (
    <>
      <tr>
        <td>{row.id}</td>
        <td>{formatDate(new Date(row.master?.createdDate))}</td>
        <td>{row.master?.clientId.slice(3)}</td>
        <td>{validateInvoiceType(row.invoiceType)}</td>
        <td>{row.master?.clientCode}</td>
        <td>{row.master?.clientName}</td>
        <td>{row.certificate.name}</td>
        <td>
          {row.totalAmount.toLocaleString("es-MX", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </td>
        <td>
          {row.taxAmount.toLocaleString("es-MX", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </td>
        <td>{validateStatus(row.master?.status)}</td>
        <td>{row.addressedTo}</td>
      </tr>
    </>
  );
};
