import { ReportsFilters } from "../../../components/board/reports/ReportsFilters";
import { ReportsTable } from "../../../components/board/reports/ReportsTable";
import Loading from "../../../components/utils/Loading";
import Navigation from "../../../components/utils/Navigation";
import { ReportsProvider } from "../../../contexts/ReportsContext";
import { useApp } from "../../../hooks/useApp";
import { properties } from "../../../utils/Properties_es";

export const Reports = () => {
  const { isLoading } = useApp();

  return (
    <ReportsProvider>
      {isLoading && <Loading title={properties.com_parval_label_loading_title} />}
      <div>
        <Navigation name={properties.com_parval_label_dashboard_menu_reports} />
      </div>
      <div className="col-12 pt-5">
        <div className="card">
          <div className="card-header">
            <ReportsFilters />
          </div>
          <div className='card-body overflow-auto'>
            <ReportsTable  />
          </div>
          
        </div>
      </div>
    </ReportsProvider>
  );
};
