/*****************************************************************************
 * REVISION 1.0
 * Fecha: 22/12/2022
 *
 * @description JS para manejo de constantes
 * @author lferrer
 * @version 1.0
 *
 ****************************************************************************/
export const CONSTANT = {
  /** DISPATCHER PREFIX */
  DISPATCHER_SECURITY_PREFIX: "/security",
  DISPATCHER_USER_PREFIX: "/users",
  DISPATCHER_MAINTENANCE_PREFIX: "/maintenance",

  /** TYPE MESSAGE */
  SUCCESS_MSG: 1,
  ERROR_MSG: 2,
  WARNING_MSG: 3,

  /** DEFAULT YES AND NOT */
  DEFAULT_YES: "S",
  DEFAULT_NOT: "N",

  /** DEPARTMENT */
  DEPARTMENT_CON_VALUE: "CON",
  DEPARTMENT_OPE_VALUE: "OPE",
  DEPARTMENT_SEC_VALUE: "SEC",
  DEPARTMENT_CUM_VALUE: "CUM",
  DEPARTMENT_ALL_VALUE: "ALL",

  /** PORTAL */
  PORTAL_DD: "DD",
  PORTAL_GL: "GL",
  PORTAL_OP: "OP",
  PORTAL_SM: "SM",
  PORTAL_AT: "AT",
  PORTAL_PP: "PP",

  /** MANAGEMENT STATUS */
  MANAGEMENT_STATUS_SLC: "SLC",
  MANAGEMENT_STATUS_ECV: "ECV",
  MANAGEMENT_STATUS_PCO: "PCO",
  MANAGEMENT_STATUS_POP: "POP",
  MANAGEMENT_STATUS_FOP: "FOP",
  MANAGEMENT_STATUS_FCO: "FCO",
  MANAGEMENT_STATUS_PSC: "PSC",
  MANAGEMENT_STATUS_FSC: "FSC",

  /** INVOICE_TYPE */
  INVOICE_TYPE_1: "CCF",
  INVOICE_TYPE_2: "CVCF",
  INVOICE_TYPE_3: "CG",
  INVOICE_TYPE_4: "CRE",

  /** TAX */
  TAX_VALUE: 0.18,

  /** IDENTIFICATION TYPE VALUE  */
  IDENTIFICATION_NATIONAL_VALUE: "N",
  IDENTIFICATION_FOREIGN_VALUE: "E",

  /** PHONETYPE */
  CODPHONE: 506,

  /** SOCIALNETWORKTYPE */
  SOCIALTYPE_FACEBOOK: "FACEBOOK",
  SOCIALTYPE_INSTAGRAM: "INSTAGRAM",
  SOCIALTYPE_TWITTER: "TWITTER",
  SOCIALTYPE_LINKEDIN: "LINKEDIN",
  SOCIALTYPE_YOUTUBE: "YOUTUBE",

  /** HEADER TYPE */
  HEADER_TYPE: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },

  /** REQUEST STATUS INFO */
  STATUS_INFO_CREATE: "CREATE",
  STATUS_INFO_ACTIVE: "ACTIVE",
  STATUS_INFO_DELETE: "DELETED",
  STATUS_INFO_UPDATED: "UPDATED",

  /** USER ROLES */
  USER_ADMINISTRATOR_ROLE: "Administrador",
  USER_EDITOR_ROLE: "Editor",
  USER_READER_ROLE: "Lector",
  /** HEADER TYPE */
  HEADER_TYPE2: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  AUTH_GRANTTYPE: "password",
}

