import { Suspense } from "react"
import { Await, useLoaderData, useOutlet } from "react-router-dom"
import { AppProvider } from "../../contexts/AppContext"
import { properties } from "../../utils/Properties_es"
import Loading from "../utils/Loading"

export const AuthLayout = () => {
  const outlet = useOutlet()

  const { authPromise } = useLoaderData()

  return (
    <Suspense
      fallback={<Loading title={properties.com_parval_label_loading_title} />}
    >
      <Await
        resolve={authPromise}
        children={(authObject) => (
          <AppProvider authData={authObject}>{outlet}</AppProvider>
        )}
      />
    </Suspense>
  )
}
