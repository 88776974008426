import { URL } from "../../../../utils/UrlConstant";
import { privateFetch } from "../../../../utils/CustomFetch";
import { properties } from "../../../../utils/Properties_es";
import { CONSTANT } from "../../../../utils/Constant";
import UrlBase from "../../../../url/Urls";


export const SVFindRequest = async (department, page, filter) => {
  let responseData = {};
  const params = new URLSearchParams([["filter", filter]]);
  await privateFetch
    .get(
      URL.REQUEST_CERTCATEGORY_LIST(
        department,
        page,
        UrlBase.registration_x_page
      ),
      { params }
    )
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(
        properties.com_parval_label_certificatecategory_find_error
      );
    });

  return responseData;
};

export const SVFindAllRequest = async () => {
  let responseData = {};
  await privateFetch
    .get(
      URL.REQUEST_CERTCATEGORY(null),
      {  }
    )
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(
        properties.com_parval_label_certificatecategory_find_error
      );
    });

  return responseData;
};

export const SVFindByIdRequest = async (id) => {
  let responseData = {};
  await privateFetch
    .get(URL.REQUEST_CERTCATEGORY(id), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(
        properties.com_parval_label_certificatecategory_findid_error
      );
    });
  return responseData;
};

export const SVSaveRequest = async (data) => {
  let responseData = {};
  const requestBody = {
    description: data.description,
    department:data.department,
    createdBy: data.createdBy,
  };
  await privateFetch
    .post(URL.REQUEST_CERTCATEGORY(null), JSON.stringify(requestBody), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(
        properties.com_parval_label_certificatecategory_save_error
      );
    });
  return responseData;
};

export const SVUpdateRequest = async (data) => {
  let responseData = {};
  const requestBody = {
    id: data.id,
    description: data.description,
    department:data.department,
    modifiedBy: data.modifiedBy,
  };
  await privateFetch
    .put(URL.REQUEST_CERTCATEGORY(null), JSON.stringify(requestBody), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(
        properties.com_parval_label_certificatecategory_update_error
      );
    });
  return responseData;
};

export const SVDeleteRequest = async (id) => {
  let responseData = {};
  await privateFetch
    .delete(URL.REQUEST_CERTCATEGORY(id), {})
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(
        properties.com_parval_label_certificatecategory_delete_error
      );
    });
  return responseData;
};
