import { URL } from "../../../utils/UrlConstant";
import { privateFetch } from "../../../utils/CustomFetch";
import { properties } from "../../../utils/Properties_es";
import { CONSTANT } from "../../../utils/Constant";
import { formatDate } from "../../../utils/UtilsFunction";

import UrlBase from "../../../url/Urls";
import axios from "axios";

export const SVFindRequest = async (page, filter) => {
  let responseData = {};
  const params = new URLSearchParams([
    ["startDate", formatDate(filter.startDate)],
    ["endDate", formatDate(filter.endDate)],
    ["identification", filter.identification],
    ["clientCode", filter.clientCode],
    ["status", filter.status === "0" ? "" : filter.status],
  ]);

  const endpoints = [
    URL.REQUEST_MANAGEMENT_DETAILS_LIST(page, UrlBase.registration_x_page),
    URL.REQUEST_MANAGEMENT_TOTALS(page, UrlBase.registration_x_page),
  ];

  await axios
    .all(endpoints.map((l) => privateFetch.get(l, { params })))
    .then(
      axios.spread((reports, totals) => {
        responseData = { reports: reports.data, totals: totals.data };
      })
    )
    .catch((error) => {
      throw new Error(properties.com_parval_label_management_find_error);
    });

  return responseData;
};

export const SVFindByIdRequest = async (id) => {
  let responseData = {};
  await privateFetch
    .get(URL.REQUEST_MANAGEMENT(id), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(properties.com_parval_label_management_findid_error);
    });
  return responseData;
};

export const SVDeleteRequest = async (id) => {
  let responseData = {};
  await privateFetch
    .delete(URL.REQUEST_MANAGEMENT(id), {})
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(properties.com_parval_label_management_delete_error);
    });
  return responseData;
};

export const SVChangeStatusRequest = async (data) => {
  let responseData = {};
  const requestBody = {
    id: data.id,
    status: data.status,
    modifiedBy: data.modifiedBy,
  };
  await privateFetch
    .put(URL.REQUEST_MANAGEMENT(null), JSON.stringify(requestBody), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(properties.com_parval_label_management_update_error);
    });
  return responseData;
};
