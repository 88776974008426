import React from "react";
import '../../resources/styles/loadingStyle.css';
/*****************************************************************************
 * REVISION 1.0
 * Fecha: 12/12/2022
 *
 * @description componente para manejo de cargando en la app
 * @author luis.ferrer
 * @version 1.0
 *
 ****************************************************************************/
const Loading = () => (
  <>
    <div className="container">
        <div className="row">  
            <div className="animationload">
                <div className="osahanloading"></div>
            </div>
        </div>
    </div>
  </>
);

export default Loading;
