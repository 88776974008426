//Urls Base
const UrlBase = {
  base_url: window.REACT_APP_BASE_URL,
  op_base_url: window.REACT_APP_OP_BASE_URL,
  op_session_base_url: window.REACT_APP_OP_SESSION_BASE_URL_ADM,
  dd_base_url: window.REACT_APP_DD_BASE_URL,
  dd_session_base_url: window.REACT_APP_DD_SESSION_BASE_URL_ADM,
  sm_base_url: window.REACT_APP_SM_BASE_URL,
  sm_session_base_url: window.REACT_APP_SM_SESSION_BASE_URL_ADM,
  at_base_url: window.REACT_APP_AT_BASE_URL,
  at_session_base_url: window.REACT_APP_AT_SESSION_BASE_URL_ADM,
  pp_base_url: window.REACT_APP_PP_BASE_URL,
  pp_session_base_url: window.REACT_APP_PP_SESSION_BASE_URL_ADM,
  security_appclient_id: window.REACT_APP_SECURITY_APPCLIENT_ID,
  security_appclient_pass: window.REACT_APP_SECURITY_APPCLIENT_PASS,
  security_appuser_id: window.REACT_APP_SECURITY_APPUSER_ID,
  security_appuser_pass: window.REACT_APP_SECURITY_APPUSER_PASS,
  parval_external_api_base_url: window.REACT_APP_PARVAL_EXTERNAL_API_BASE_URL,
  parval_external_return_url: window.REACT_APP_PARVAL_EXTERNAL_RETURN_URL,

  registration_x_page: window.REACT_APP_REGISTRATION_X_PAGE,
  modal_registration_x_page: window.REACT_APP_MODAL_REGISTRATION_X_PAGE,
  certificate_registration_x_page:
    window.REACT_APP_CERTIFICATE_REGISTRATION_X_PAGE,
  sesion_timeout: window.REACT_APP_SESION_TIMEOUT,
  sesion_timeout_wait: window.REACT_APP_SESION_TIMEOUT_WAIT,
  parval_encrypt_secret_key: window.REACT_APP_PARVAL_ENCRYPT_SECRET_KEY,
  parval_cs_payer_url: window.REACT_APP_PARVAL_CS_PAYER_URL,
};

export default {
  //Url's de inicio y cierre de sesión
  base_url: `${UrlBase.base_url}`,
  op_base_url: `${UrlBase.op_base_url}`,
  op_session_base_url: `${UrlBase.op_session_base_url}`,
  dd_base_url: `${UrlBase.dd_base_url}`,
  dd_session_base_url: `${UrlBase.dd_session_base_url}`,
  at_base_url: `${UrlBase.at_base_url}`,
  at_session_base_url: `${UrlBase.at_session_base_url}`,
  sm_base_url: `${UrlBase.sm_base_url}`,
  sm_session_base_url: `${UrlBase.sm_session_base_url}`,
  pp_base_url: `${UrlBase.pp_base_url}`,
  pp_session_base_url: `${UrlBase.pp_session_base_url}`,
  security_appclient_id: `${UrlBase.security_appclient_id}`,
  security_appclient_pass: `${UrlBase.security_appclient_pass}`,
  security_appuser_id: `${UrlBase.security_appuser_id}`,
  security_appuser_pass: `${UrlBase.security_appuser_pass}`,
  parval_external_api_base_url: `${UrlBase.parval_external_api_base_url}`,
  parval_external_return_url: `${UrlBase.parval_external_return_url}`,
  registration_x_page: `${UrlBase.registration_x_page}`,
  modal_registration_x_page: `${UrlBase.modal_registration_x_page}`,
  certificate_registration_x_page: `${UrlBase.certificate_registration_x_page}`,
  sesion_timeout: `${UrlBase.sesion_timeout}`,
  sesion_timeout_wait: `${UrlBase.sesion_timeout_wait}`,
  parval_encrypt_secret_key: `${UrlBase.parval_encrypt_secret_key}`,
  parval_cs_payer_url: `${UrlBase.parval_cs_payer_url}`,
};
