/*****************************************************************************
 * REVISION 1.0
 * Fecha: 22/12/2022
 *
 * @description JS para manejo de labels
 * @version 1.0
 *
 ****************************************************************************/
export const properties = {
    /** General */

    /** footer */
    com_parval_label_footer_terms_conditions: "Términos y Condiciones",
    /** Page not found */
    com_parval_label_page_404_title: "Oops!",
    com_parval_label_page_404_subtitle: "Página no encontrada",

    /** Loading */
    com_parval_label_loading_title: "Cargando",

    /** Home */
    com_parval_label_home_title: "Parval",

    /** General label */
    com_parval_label_yes: "Si",
    com_parval_label_not: "No",
    com_parval_label_add_subtitle: "Ingrese los datos indicados",
    com_parval_label_update_subtitle: "Modifique los datos indicados",
    com_parval_label_code: "Código",
    com_parval_label_codetfa: "Código de validación",
    com_parval_label_phone: "Teléfono",
    com_parval_label_url: "Url",
    com_parval_label_category: "Categoría",
    com_parval_label_name: "Nombre",
    com_parval_label_lastname: "Apellido",
    com_parval_label_state: "Estado",
    com_parval_label_identification: "Cédula",
    com_parval_label_clientcode: "Código de cliente",
    com_parval_label_role: "Perfil",
    com_parval_label_createat: "Fecha de creación",
    com_parval_label_createat2: "Fecha solicitud",
    com_parval_label_email: "Correo electrónico",
    com_parval_label_password: "Contraseña",
    com_parval_label_passwordrepeat: "Repita contraseña",
    com_parval_label_id: "ID",
    com_parval_label_paymentType: "Tipo de pago",
    com_parval_label_card: "Tarjeta",
    com_parval_label_description: "Descripción",
    com_parval_label_price: "Precio",
    com_parval_label_subtotal: "SubTotal",
    com_parval_label_total: "Total",
    com_parval_label_amount: "Monto",
    com_parval_label_total_amount: "Monto Total",
    com_parval_label_tax: "ITBIS (18%)",
    com_parval_label_tax2: 'ITBIS',
    com_parval_label_total_tax: 'ITBIS Total',
    com_parval_label_applytax: "Aplicar ITBIS (18%)",
    com_parval_label_taxes: "Impuesto",
    com_parval_label_department: "Departamento",
    com_parval_label_generationtime: "Tiempo generación",
    com_parval_label_comment: "Comentario",
    com_parval_label_free: "Gratis",
    com_parval_label_zero: "0",
    com_parval_label_select: "Seleccione",
    com_parval_label_select_other: "Otro",
    com_parval_label_not_apply: "No aplica",
    com_parval_label_certificate: "Certificado",
    com_parval_label_certificate_name: 'Nombre del Certificado',
    com_parval_label_account: "Cuenta",
    com_parval_label_time: "Duración",
    com_parval_label_observacion: "Observación",
    com_parval_label_date: 'Fecha',
    com_parval_label_initdate: "Fecha inicio",
    com_parval_label_specificdate: "Fecha Especifica",
    com_parval_label_enddate: "Fecha final",
    com_parval_label_to: "Dirigido a",
    com_parval_label_invoicetype: "Tipo de factura",
    com_parval_label_language: "Idioma",
    com_parval_label_department_con_title: "Contabilidad",
    com_parval_label_department_ope_title: "Operaciones",
    com_parval_label_department_sec_title: "Servicio al cliente",
    com_parval_label_department_cum_title: "Cumplimiento",
    com_parval_label_department_all_title: "Todos",
    com_parval_label_permission_title: "Permiso",
    /** General error labels in forms */
    com_parval_label_field_required: "Campo requerido",
    com_parval_label_field_format: "Formato invalido",
    com_parval_label_field_maxlenght: (lenght) => `Longitud máxima permitida (${lenght})`,
    com_parval_label_field_minlenght: (lenght) => `Longitud minima permitida (${lenght})`,
    /** General sucess labels in process */
    com_parval_label_request_save: "Registro insertado con exito",
    com_parval_label_request_update: "Registro actualizado con exito",
    com_parval_label_request_delete: "Registro eliminado con exito",
    /** General error labels in process */
    com_parval_label_request_connection: "Falla en solicitud al servidor",
    com_parval_label_request_unauthorized: "No tiene permiso para realizar esta acción",
    com_parval_label_request_badrequest: "Falla en parametros dentro de la solicitud al servidor",

    /** General button labels */
    com_parval_label_button_accept_title: "Aceptar",
    com_parval_label_button_ok_title: "Ok",
    com_parval_label_button_cancel_title: "Cancelar",
    com_parval_label_button_save_title: "Registrar",
    com_parval_label_button_gen_title: "Generar",
    com_parval_label_button_update_title: "Editar",
    com_parval_label_button_detail_title: "Ver detalle",
    com_parval_label_button_changestate_title: "Cambiar estado",
    com_parval_label_button_delete_title: "Eliminar",
    com_parval_label_button_back_title: "Atras",
    com_parval_label_button_back2_title: "Volver",
    com_parval_label_button_add_title: "Agregar",
    com_parval_label_button_search_title: "Buscar",
    com_parval_label_button_clear_title: "Limpiar",
    com_parval_label_button_login_title: "Iniciar Sesión",
    com_parval_label_button_close_title: "Cerrar",
    com_parval_label_button_view_title: "Ver",
    com_parval_label_button_exit_title: "Salir",
    com_parval_label_button_forgotpass_title: "Recuperar contraseña",
    com_parval_label_button_updatepass_title: "Actualizar contraseña",

    /** Table label */
    com_parval_label_table_filter_title: "Filtrar",
    com_parval_label_table_filter_title2: "Filtrar por:",
    com_parval_label_table_filter_placeholder: "Filtro de búsqueda",
    com_parval_label_table_action: "Acciones",
    com_parval_label_table_empty: "No existen registros",

    /** General modal label  */
    com_parval_label_modal_delete_title: "Eliminar Registro",

    /** Header dashboard label */
    com_parval_label_dashboard_header_title: "Gestiones en Línea",
    /** Menu dashboard label */
    com_parval_label_dashboard_menu_principal: "Portal Administrativo",
    com_parval_label_dashboard_menu_inicio: "Inicio",
    com_parval_label_dashboard_menu_logout: "Cerrar sesión",
    com_parval_label_dashboard_menu_management: "Gestiones",
    com_parval_label_dashboard_menu_reports: "Reportes",
    /** maintenance admin menu */
    com_parval_label_dashboard_menu_maintenance: "Mantenimientos",
    com_parval_label_dashboard_menu_maintenance_certificatecategory: "Categoría certificado",
    com_parval_label_dashboard_menu_maintenance_certificate: "Certificado",
    /** configuration admin menu */
    com_parval_label_dashboard_menu_config: "Configuraciones",
    com_parval_label_dashboard_menu_config_user: "Usuarios",
    com_parval_label_dashboard_menu_config_profile: "Perfiles",
    com_parval_label_dashboard_menu_config_permissions: "Permisos",
    com_parval_label_dashboard_menu_config_permissionsxrole: "Permisos por perfil",

    /** login */
    com_parval_label_login_title: "Portal Administrativo",
    com_parval_label_login_forget_password_title: "¿Olvidaste tu contraseña?",
    com_parval_label_login_portal_select_title: "Seleccione portal",
    com_parval_label_login_portal_dd_title: "Due Diligence",
    com_parval_label_login_portal_gl_title: "Gestiones en Línea",
    com_parval_label_login_portal_op_title: "Operaciones",
    com_parval_label_login_portal_sm_title: "Simulador Mutuos",
    com_parval_label_login_portal_at_title: "Activos Fijos",
    com_parval_label_login_portal_pp_title: "Presupuesto",

    /** login success*/
    com_parval_label_login_twofactor_success: "Código de validación enviado al correo",
    /** login error*/
    com_parval_label_login_twofactor_error: "Error en proceso de enviar código de validación",
    com_parval_label_login_error: "Correo o contraseña inválida",
    com_parval_label_login_error2: "Usuario no tiene permiso de loguearse en este sitio",
    com_parval_label_login_error3: "El usuario no está activo",
    com_parval_label_login_general_error: "Error en proceso de ingresar al portal administrativo",
    com_parval_label_login_logout_error: "Error en proceso de cerrar sesion",

    /** RecoveryPassword */
    com_parval_label_recoverypass_title: "Recuperar Contraseña",
    com_parval_label_recoverypass_back_title: "Regresar a login",
    /** ForgotPassword success*/
    com_parval_label_recoverypass_general_sucess: "El correo de recuperación a sido enviado",
    /** ForgotPassword error*/
    com_parval_label_recoverypass_general_error: "Error en proceso de recuperar contraseña",

    /** ForgotPassword */
    com_parval_label_forgotpass_title: "Cambiar Contraseña",
    /** ForgotPassword success*/
    com_parval_label_forgotpass_general_sucess: "El cambio de contraseña se completo",
    /** ForgotPassword error*/
    om_parval_label_forgotpass_different_password_error: "Las contraseñas no coinciden",
    com_parval_label_forgotpass_general_error: "Error en proceso de cambiar contraseña",

    /** ActiveRegister */
    com_parval_label_activeregister_title: "Activación Usuario",
    /** ActiveRegister success*/
    com_parval_label_activeregister_general_sucess: "La activación del usuario se completó",
    com_parval_label_activeregister_general_sucess2: "La activación del usuario se completó correctamente.",
    /** ActiveRegister error*/
    com_parval_label_activeregister_general_error: "Error en proceso de activar usuario",

    /** CertificateCategory */
    com_parval_label_certificatecategory_button_add_title: "Agregar categoría",
    com_parval_label_certificatecategory_add_title: "Agregar categoría certificado",
    com_parval_label_certificatecategory_update_title: "Editar categoría certificado",

    /** CertificateCategory service error label */
    com_parval_label_certificatecategory_find_error: "Error en consulta de categorías de certificado",
    com_parval_label_certificatecategory_findid_error: "Error en consulta por id de categoría de certificado",
    com_parval_label_certificatecategory_save_error: "Error en proceso de guardar categoría de certificado",
    com_parval_label_certificatecategory_update_error: "Error en proceso de actualizar categoría de certificado",
    com_parval_label_certificatecategory_delete_error: "Error en proceso de eliminar categoría de certificado",

    /** Certificate */
    com_parval_label_certificate_button_add_title: "Agregar certificado",
    com_parval_label_certificate_add_title: "Agregar certificado",
    com_parval_label_certificate_update_title: "Editar certificado",
    com_parval_label_certificate_generationtime_placeholder_desc: "Ingrese tiempo en días hábiles",
    com_parval_label_certificate_generationtime_desc_format: (h, m) => `${h}(h):${m}(m)`,
    com_parval_label_certificate_generationtime_desc2_format: (d) => `${d} día${d > 1 ? "s" : ""}`,

    /** Certificate service error label */
    com_parval_label_certificate_find_error: "Error en consulta de certificados",
    com_parval_label_certificate_findid_error: "Error en consulta por id de certificado",
    com_parval_label_certificate_save_error: "Error en proceso de guardar certificado",
    com_parval_label_certificate_update_error: "Error en proceso de actualizar certificado",
    com_parval_label_certificate_delete_error: "Error en proceso de eliminar certificado",

    /** Users */
    com_parval_label_user_button_add_title: "Agregar usuario",
    com_parval_label_user_add_title: "Agregar usuario",
    com_parval_label_user_update_title: "Editar usuario",
    com_parval_label_user_update_pass_title: "Actualizar contraseña de usuario",
    /** Users service error label */
    com_parval_label_user_find_error: "Error en consulta de usuarios",
    com_parval_label_user_findid_error: "Error en consulta por id de usuario",
    com_parval_label_user_save_error: "Error en proceso de guardar usuario",
    com_parval_label_user_update_error: "Error en proceso de actualizar usuario",
    com_parval_label_user_delete_error: "Error en proceso de eliminar usuario",
    com_parval_label_clientprofile_error: "Error en proceso de obtener los datos del perfil cliente",
    com_parval_label_clientprofile_error2: "No existen perfil asociado al cliente, cliente no existe",
    com_parval_label_document_gen_error: "Error en proceso de generar documento",
    com_parval_label_document_gen_error2: "Error en proceso de generar documento, no existe certificado",

    /** Roles */
    com_parval_label_user_button_add_role: "Agregar perfil",
    com_parval_label_role_adminlogin_title: "Login Portal Admin",
    com_parval_label_role_clientlogin_title: "Login Portal Ejecutivo",
    com_parval_label_role_add_title: "Agregar perfil",
    com_parval_label_role_update_title: "Editar perfil",
    /** Roles service error label */
    com_parval_label_role_find_error: "Error en consulta de perfiles",
    com_parval_label_role_findid_error: "Error en consulta por id de perfil",
    com_parval_label_role_save_error: "Error en proceso de guardar perfil",
    com_parval_label_role_update_error: "Error en proceso de actualizar perfil",
    com_parval_label_role_delete_error: "Error en proceso de eliminar perfil",

    /** Authority */
    com_parval_label_authority_add_title: "Agregar permiso",
    com_parval_label_authority_update_title: "Editar permiso",
    /** Roles service error label */
    com_parval_label_authority_find_error: "Error en consulta de permisos",
    com_parval_label_authority_findid_error: "Error en consulta por id de permiso",
    com_parval_label_authority_save_error: "Error en proceso de guardar permiso",
    com_parval_label_authority_update_error: "Error en proceso de actualizar permiso",
    com_parval_label_authority_delete_error: "Error en proceso de eliminar permiso",

    /** Authority for Role */
    com_parval_label_authorityxrole_view_title: "Permisos por perfil",
    com_parval_label_authoritiesxrole_update_title: "Editar permiso al perfil",
    com_parval_label_authoritiesxrole_addauthority_title: "Agregar permiso al perfil",
    com_parval_label_authoritiesxrole_find_error: "Error en consulta de permisos por perfil",
    com_parval_label_authoritiesxrole_findid_error: "Error en consulta por id del permiso por perfil",
    com_parval_label_authoritiesxrole_save_error: "Error en proceso de guardar permiso por perfil",
    com_parval_label_authoritiesxrole_delete_error: "Error en proceso de eliminar permiso por perfil",

    /** Management */
    com_parval_label_selectstatus: "Seleccione estado",
    com_parval_label_management_status_slc_title: "Solicitado",
    com_parval_label_management_status_ecv_title: "Enviado a Cevaldom",
    com_parval_label_management_status_pco_title: "Proceso en Contabilidad",
    com_parval_label_management_status_pop_title: "Proceso en Operaciones",
    com_parval_label_management_status_fop_title: "Finalizado en Operaciones",
    com_parval_label_management_status_fco_title: "Finalizado en Contabilidad",
    com_parval_label_management_status_psc_title: "Proceso en Servicio al Cliente",
    com_parval_label_management_status_fsc_title: "Finalizado en Servicio al Cliente",
    com_parval_label_management_status_unknown_title: "Desconocido",
    com_parval_label_management_update_title: "Editar estado",
    com_parval_label_management_invoicetype1_title: "Comprobantes de consumidor final",
    com_parval_label_management_invoicetype2_title: "Comprobante válido para crédito fiscal",
    com_parval_label_management_invoicetype3_title: "Comprobantes gubernamentales",
    com_parval_label_management_invoicetype4_title: "Comprobantes para regímenes especiales",
    /** Management success*/
    com_parval_label_management_statusupdate_general_sucess: "El cambio de estado se completo",
    /** Management service error label */
    com_parval_label_management_find_error: "Error en consulta de gestiones",
    com_parval_label_management_findid_error: "Error en consulta por id de gestión",
    com_parval_label_management_save_error: "Error en proceso de guardar gestión",
    com_parval_label_management_update_error: "Error en proceso de actualizar gestión",
    com_parval_label_management_delete_error: "Error en proceso de eliminar gestión",

    /** Reports */


    /** PARVAL CLIENT ACCOUNT MSG*/
    com_parval_label_clientaccount_error: "Error en proceso de obtener el listado de cuentas del cliente",
    com_parval_label_clientaccount_error2: "No existen cuentas asociadas al cliente, cliente no existe",
};