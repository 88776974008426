import { useEffect } from "react";
import { properties } from "../../../../utils/Properties_es";
import CustomPagination from "../../../utils/CustomPagination";
import { ReportsTR } from "./ReportsTR";
import { useFilter } from "../../../../hooks/useFilter";
import { ReportTH } from "./ReportsTH";
import { ReportTF } from "./ReportsTF";

export const ReportsTable = () => {
  const [reportsData, { page, totals, dataInit, pagination }] = useFilter();

  useEffect(() => {
    if (!reportsData || !reportsData.content || reportsData.content.length < 1) {
      dataInit();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <table className="table" style={{ minWidth: 1300 }}>
        <ReportTH />
        <tbody className="datos-user text-center">
          {reportsData &&
            reportsData.content &&
            reportsData.content?.map((r) => <ReportsTR key={r.id} row={r} />)}
          {(!reportsData || !reportsData.content || reportsData.content.length <= 0) && (
            <tr>
              <td colSpan="12">{properties.com_parval_label_table_empty}</td>
            </tr>
          )}
        </tbody>
        <ReportTF totals={totals}/>
      </table>
      {reportsData && reportsData.content && (
        <div className="card-footer">
          <div className="row">
            <div className="col-12">
              <CustomPagination
                activePage={page}
                itemsCountPerPage={reportsData.size}
                totalItemsCount={reportsData.totalElements}
                pageRangeDisplayed={5}
                innerClass="pagination justify-content-end"
                itemClass="page-item"
                linkClass="page-link"
                callback={pagination}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
