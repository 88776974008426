import React, { createContext, useMemo, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { setAuthData } from "../utils/LocalStorageManager";
import jwt_decode from "jwt-decode";
//define context
const AppContext = createContext();
const { Provider } = AppContext;

const AppProvider = ({ children, authData }) => {
  const [authInfo, setAuthInfo] = useState(authData);
  const [isLoading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [successMsg, setSuccessMsg] = useState(null);
  const [clientProfile, setProfileData] = useState({});
  const [modalData, setModalData] = useState({
    modalOpen: false,
    modalType: null,
    modalObject: null,
  });

  const [authenticate, setAuthenticate] = useState(() => {
    if (!authData) {
      return false;
    }
    const decodeToken = jwt_decode(authData?.tokenInfo?.access_token);
    const expiresAt = decodeToken?.exp;
    let valid = new Date().getTime() / 1000 < expiresAt;
    if (valid) {
      return true;
    }
    return false;
  });
  const navigate = useNavigate();

  const loginProvider = async (loginData) => {
    try {
      //save login data in storage
      const authData = await setAuthData("authAdminInfo", loginData);
      if (!authData) {
        setAuthenticate(false);
        return;
      }
      const decodeToken = jwt_decode(authData?.tokenInfo?.access_token);
      const expiresAt = decodeToken?.exp;
      let valid = new Date().getTime() / 1000 < expiresAt;
      if (valid) {
        setAuthenticate(valid);
        setAuthInfo(authData);
        navigate("/board/home", { replace: true });
        return;
      }
    } catch (error) {
      console.log(error);
    }
    setAuthenticate(false);
  };

  const logoutProvider = async (e) => {
    //e.preventDefault();
    await setAuthData("authAdminInfo", null);
    setAuthenticate(false);
    navigate("/", { replace: true });
  };

  //Recordar descomentar
  useEffect(() => {
    let time = 300000;
    let timeOutTimer = setTimeout(logoutProvider, time);
    const checkInactivity = () => {
      window.onclick = () => checkInactivity();
      window.onkeypress = () => checkInactivity();
      window.addEventListener("mousemove", checkInactivity);
      clearTimeout(timeOutTimer);
      timeOutTimer = setTimeout(logoutProvider, time);
    };
    checkInactivity();
  }, []);

  const resetErrorMsg = () => {
    setErrorMsg(null);
  };

  const resetSuccessMsg = () => {
    setSuccessMsg(null);
  };

  const resetAllMsg = () => {
    setErrorMsg(null);
    setSuccessMsg(null);
  };

  useEffect(() => {
    console.log(authenticate);
  }, [authenticate]);

  const values = useMemo(
    () => ({
      authInfo,
      authenticate,
      isLoading,
      errorMsg,
      successMsg,
      modalData,
      clientProfile,
      loginProvider,
      logoutProvider,
      setLoading,
      resetErrorMsg,
      setErrorMsg,
      resetSuccessMsg,
      setSuccessMsg,
      resetAllMsg,
      setModalData,
      setProfileData,
    }),
    // eslint-disable-next-line
    [
      authInfo,
      authenticate,
      isLoading,
      errorMsg,
      successMsg,
      modalData,
      clientProfile,
    ]
  );

  return <Provider value={values}>{children}</Provider>;
};

export { AppContext, AppProvider };
