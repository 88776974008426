import { Col, Row } from "react-bootstrap";
import { properties } from "../../../../utils/Properties_es";

export const ReportTF = ({ totals }) => (
  <tfoot className="text-green2 text-center">
    <tr>
      <td colSpan="12">
        <Row className="justify-content-end">
          <Col className="col-6 col-md-auto text-end">
            {`${properties.com_parval_label_total_amount}: `}
            <span className="datos-user ms-2">
              {Number(totals.total_amount).toLocaleString("es-MX", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </span>
          </Col>
          <Col className="col-6 col-md-auto text-end">
            {`${properties.com_parval_label_total_tax}: `}
            <span className="datos-user ms-2">
              {Number(totals.tax_amount).toLocaleString("es-MX", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </span>
          </Col>
        </Row>
      </td>
    </tr>
  </tfoot>
);
