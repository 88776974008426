import { useState } from "react";
import { useApp } from "./useApp";
import { SVFindRequest } from "../services/board/reports/ReportsServices";
import { SVFindAllRequest } from "../services/board/maintenance/certificateCategory/CertificateCategoryService";
import { useReports } from "./useReports";

export const useFilter = () => {
  const { setLoading, setErrorMsg } = useApp();
  const {
    reportsData,
    setReportsData,
    filter,
    setFilter,
    page,
    setPage,
    filterObject,
    totals,
    setTotals,
  } = useReports();

  const [certCatData, setCertCatData] = useState([]);

  const handleApplyFilter = async (e) => {
    await handleFetchData(false, filter, 0);
  };

  const handleClearFilter = async (e) => {
    setFilter(filterObject);
    //setDetailList([])
    await handleFetchData(false, filterObject, 0);
  };

  const pagination = async (pageNumber) => {
    let customPage = pageNumber - 1;
    if (customPage !== page) {
      await handleFetchData(false, filter, customPage);
    }
  };

  /**
   *
   * Metodo encargado de buscar registros
   *
   * @param filter
   * @param currentPage
   */
  const handleFetchData = async (applyCategory, filter, currentPage) => {
    setLoading(true);
    try {
      setPage(currentPage);
      if (applyCategory) {
        let catData = await SVFindAllRequest();
        if (catData) {
          setCertCatData(catData);
        }
      }

      SVFindRequest(currentPage, filter)
        .then(({ reports, totals }) => {
          if (reports?.content && totals) {
            setReportsData(reports);
            setTotals(totals);
            setLoading(false);
          } else {
            setLoading(false);
            setErrorMsg("No se encontraron registros");
          }
        })
        .catch((error) => {
          setLoading(false);
          setErrorMsg(error.message);
        });
    } catch (error) {
      setLoading(false);
      setErrorMsg(error.message);
    }
  };

  const dataInit = async () => {
    await handleFetchData(true, filter, 0);
  };

  return [
    reportsData,
    {
      filter,
      page,
      certCatData,
      totals,
      setFilter,
      dataInit,
      handleApplyFilter,
      handleClearFilter,
      pagination,
    },
  ];
};
