import { properties } from "../../../../utils/Properties_es";

export const ReportTH = () => (
  <thead className="text-green2 text-center">
    <tr>
      <th>{properties.com_parval_label_id}</th>
      <th>{properties.com_parval_label_date}</th>
      <th>{properties.com_parval_label_identification}</th>
      <th>{properties.com_parval_label_invoicetype}</th>
      <th>{properties.com_parval_label_clientcode}</th>
      <th>{properties.com_parval_label_name}</th>
      <th>{properties.com_parval_label_certificate_name}</th>
      <th>{properties.com_parval_label_amount}</th>
      <th>{properties.com_parval_label_tax}</th>
      <th>{properties.com_parval_label_state}</th>
      <th>{properties.com_parval_label_to}</th>
    </tr>
  </thead>
);
