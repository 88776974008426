import { subMonths } from "date-fns";
import { createContext, useMemo, useState } from "react";

const ReportsContext = createContext();

const ReportsProvider = ({ children }) => {
  const filterObject = {
    minDate: subMonths(new Date(), 3),
    maxDate: new Date(),
    startDate: subMonths(new Date(), 1),
    endDate: new Date(),
    identification: "",
    clientCode: "",
    status: "0",
  };
  /**
   * States
   */
  const [reportsData, setReportsData] = useState({});
  const [ totals, setTotals ] = useState({});
  const [filter, setFilter] = useState(filterObject);
  const [page, setPage] = useState(0);

  const values = useMemo(
    () => ({
      reportsData,
      filter,
      page,
      totals,
      filterObject,
      setReportsData,
      setTotals,
      setFilter,
      setPage,
    }),
    // eslint-disable-next-line
    [reportsData, filter, page, totals]
  );

  return <ReportsContext.Provider value={values}>{children}</ReportsContext.Provider>;
};

export { ReportsContext, ReportsProvider };
