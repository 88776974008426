import React, { lazy } from "react"
import {
  Route,
  defer,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom"
import { AuthLayout } from "../components/template/AuthLayout"
import { getAuthData } from "../utils/LocalStorageManager"
import { Reports } from "../pages/board/reports/Reports"

/** Template */
const HomeLayout = lazy(() => import("../components/template/HomeLayout"))
const BoardLayout = lazy(() => import("../components/template/BoardLayout"))

/** Main page */
const Login = lazy(() => import("../pages/home/login/Login"))
const RecoveryPassword = lazy(() =>
  import("../pages/home/recoveryPassword/RecoveryPassword")
)
const ForgotPassword = lazy(() =>
  import("../pages/home/forgotPassword/ForgotPassword")
)
const ActiveRegister = lazy(() =>
  import("../pages/home/activeRegister/ActiveRegister")
)

/** Board home page */
const Home = lazy(() => import("../pages/board/home/Home"))

/** Board maintenance pages */
const Management = lazy(() => import("../pages/board/management/Management"))

/** Board maintenance pages */
const CertificateCategory = lazy(() =>
  import("../pages/board/maintenance/certificateCategory/CertificateCategory")
)
const Certificate = lazy(() =>
  import("../pages/board/maintenance/certificate/Certificate")
)

/** Board config pages */
const User = lazy(() => import("../pages/board/config/user/User"))
const Role = lazy(() => import("../pages/board/config/role/Role"))
const Authority = lazy(() =>
  import("../pages/board/config/authority/Authority")
)
const AuthoritiesXRole = lazy(() =>
  import("../pages/board/config/authoritiesxrole/AuthoritiesXRole")
)

export const appRoutes = createBrowserRouter(
  createRoutesFromElements(
    <Route
      element={<AuthLayout />}
      loader={async () => defer({ authPromise: getAuthData() })}
    >
      <Route element={<HomeLayout />}>
        <Route exact path='/' element={<Login />} />
        <Route exact path='/recover-password' element={<RecoveryPassword />} />
        <Route
          exact
          path='/forgot-password/:portal/:code/:email'
          element={<ForgotPassword />}
        />
        <Route
          exact
          path='/active-register/:portal/:code/:email'
          element={<ActiveRegister />}
        />
      </Route>
      <Route path='/board' element={<BoardLayout />}>
        <Route exact path='home' element={<Home />} />
        <Route exact path='management' element={<Management />} />
        <Route exact path='reports' element={<Reports />} />
        <Route
          exact
          path='certificatecategory'
          element={<CertificateCategory />}
        />
        <Route exact path='certificate' element={<Certificate />} />
        <Route exact path='user' element={<User />} />
        <Route exact path='role' element={<Role />} />
        <Route exact path='authority' element={<Authority />} />
        <Route
          exact
          path='authoritiesxrole/:roleId'
          element={<AuthoritiesXRole />}
        />
      </Route>
    </Route>
  )
)
